import React from 'react';

import { arrowRight, tax, bank, headphonesCustomerSupport } from 'icons/default';
import { CarPrice } from 'icons/symbol';
import { ButtonVariant } from 'components/Button';

export const dropdownMenuBuy = {
  title: 'Köpa',
  list: [
    {
      item: <>Köpa bil</>,
      variant: ButtonVariant.Text,
      leadingIcon: CarPrice,
      trailingIcon: arrowRight,
      href: '/kopa-begagnad-bil',
    },
    {
      item: <>Boka tid med Blipp</>,
      variant: ButtonVariant.Text,
      leadingIcon: headphonesCustomerSupport,
      trailingIcon: arrowRight,
      href: '/boka-tid',
    },
  ],
};

export const dropdownMenuCompany = {
  title: 'Företag',
  list: [
    {
      item: <>Företag</>,
      variant: ButtonVariant.Text,
      leadingIcon: bank,
      trailingIcon: arrowRight,
      href: '/foretag',
    },
    {
      item: <>Företagsleasing</>,
      variant: ButtonVariant.Text,
      leadingIcon: CarPrice,
      trailingIcon: arrowRight,
      href: '/foretag/foretagsleasing',
    },
    {
      item: <>Re:lease - Lägg om din leasing</>,
      variant: ButtonVariant.Text,
      leadingIcon: tax,
      trailingIcon: arrowRight,
      href: '/foretag/release',
    },
  ],
};
