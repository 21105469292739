import { FooterNavListProps } from '../FooterNavList';

export const footerNavList: FooterNavListProps[] = [
  {
    heading: 'Köp & Sälj',
    list: [
      { text: 'Köpa bil', href: '/kopa-begagnad-bil' },
      { text: 'Sälja bil', href: '/salja-begagnad-bil' },
      { text: 'Köpa bil på Blocket', href: '/blocket-bilar' },
    ],
  },
  {
    heading: 'Våra tjänster',
    list: [
      { text: 'Billån', href: '/lanelofte-billan' },
      { text: 'Värdera bil', href: '/salja-begagnad-bil' },
      { text: 'Kontrollera bil', href: '/kopa-begagnad-bil' },
      { text: 'Företagsleasing', href: '/foretag/foretagsleasing' },
      { text: 'Re:Lease - Lägg om din företagsleasing', href: '/foretag/release' },
    ],
  },
  {
    heading: 'Bra att veta',
    list: [
      { text: 'Försäkring & Garanti', href: '/forsakring-och-garanti' },
      { text: 'Blipp förklarar', href: '/blipp-forklarar' },
      { text: 'Magazine', href: '/magazine' },
      { text: 'Om oss', href: '/om' },
      { text: 'Villkor', href: '/villkor' },
    ],
  },
];
