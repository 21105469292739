import React, { MouseEventHandler } from 'react';
import { useRouter } from 'next/router';

import { arrowRight, bank, buy, sale, singleNeutral, close, heart, bulb } from 'icons/default';
import { CarActionsCheck } from 'icons/symbol';
import { Button, ButtonVariant, ButtonType } from 'components/Button';
import Logotype from 'public/assets/logotype/logotype.svg';
import { Accordion, AccordionItem } from 'components/Accordion';

import styles from './SideNavigation.module.scss';

interface IProps {
  isLoggedIn: boolean;
  onClickClose: () => void;
  open: boolean;
  handleLogout: MouseEventHandler<HTMLButtonElement>;
}

export const SideNavigation: React.FC<IProps> = ({
  isLoggedIn,
  onClickClose,
  open = false,
  handleLogout,
}) => {
  const router = useRouter();

  return (
    <div className={`${styles.container} ${open ? styles.open : ''}`}>
      <div className={styles.wrapper}>
        <div className={styles['close-button']}>
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Text}
            leadingIcon={close}
            stretch={false}
            onClick={() => onClickClose()}
          />
        </div>

        <Logotype className={styles.logotype} />

        <span className={styles.tagline}>My ride. My rules.</span>

        <div className={styles['main-nav']}>
          <nav className={styles['main-nav-links']}>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Ghost}
              leadingIcon={buy}
              trailingIcon={arrowRight}
              stretch={false}
              href="/kopa-begagnad-bil"
            >
              Köpa
            </Button>

            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Ghost}
              leadingIcon={bank}
              trailingIcon={arrowRight}
              stretch={false}
              href="/lanelofte-billan"
            >
              Billån
            </Button>

            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Ghost}
              leadingIcon={CarActionsCheck}
              trailingIcon={arrowRight}
              stretch={false}
              href="/reloan"
            >
              Flytta ditt billån
            </Button>

            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Ghost}
              leadingIcon={bulb}
              trailingIcon={arrowRight}
              stretch={false}
              href="/magazine"
            >
              Magazine
            </Button>

            {!isLoggedIn && (
              <div className={styles['login-button']}>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Primary}
                  trailingIcon={singleNeutral}
                  href="/app/dashboard"
                >
                  Logga in
                </Button>
              </div>
            )}
          </nav>

          <nav className={styles['main-nav-links-mobile']}>
            {isLoggedIn && (
              <div className={styles['main-nav-links-profile']}>
                <Accordion divider={false}>
                  <AccordionItem icon={heart} title="Mitt blipp">
                    <div className={styles['accordion-buttons']}>
                      <Button
                        type={ButtonType.Button}
                        variant={ButtonVariant.Text}
                        trailingIcon={arrowRight}
                        href="/app/meddelanden"
                      >
                        Mina meddelanden
                      </Button>
                      <Button
                        type={ButtonType.Button}
                        variant={ButtonVariant.Text}
                        trailingIcon={arrowRight}
                        href="/app/loan"
                      >
                        Mina lån
                      </Button>

                      <Button
                        type={ButtonType.Button}
                        variant={ButtonVariant.Text}
                        trailingIcon={arrowRight}
                        href="/app/dashboard"
                      >
                        Mina affärer
                      </Button>

                      <Button
                        type={ButtonType.Button}
                        variant={ButtonVariant.Text}
                        trailingIcon={arrowRight}
                        href="/app/settings"
                      >
                        Inställningar
                      </Button>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
            )}

            <Accordion divider={false}>
              <AccordionItem
                icon={CarActionsCheck}
                title="Flytta ditt billån"
                onClick={() => router.push('/reloan')}
              />

              <AccordionItem icon={buy} title="Köpa">
                <div className={styles['accordion-buttons']}>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/kopa-begagnad-bil"
                  >
                    Köpa bil
                  </Button>

                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/boka-tid"
                  >
                    Boka tid med Blipp
                  </Button>
                </div>
              </AccordionItem>

              <AccordionItem
                icon={bank}
                title="Billån"
                onClick={() => router.push('/lanelofte-billan')}
              />

              <AccordionItem
                icon={sale}
                title="Sälja"
                onClick={() => router.push('/salja-begagnad-bil')}
              />
              <AccordionItem title="Företag">
                <div className={styles['accordion-buttons']}>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/foretag"
                  >
                    Företag
                  </Button>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/foretag/foretagsleasing"
                  >
                    Företagsleasing
                  </Button>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/foretag/release"
                  >
                    Re:lease - Lägg om din leasing
                  </Button>
                </div>
              </AccordionItem>

              <AccordionItem title="Blipp Magazine" onClick={() => router.push('/magazine')} />
              {isLoggedIn && <AccordionItem title="Logga ut" onClick={handleLogout} />}
            </Accordion>

            {!isLoggedIn && (
              <div className={styles['login-button']}>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Primary}
                  trailingIcon={singleNeutral}
                  href="/app/dashboard"
                >
                  Logga in
                </Button>
              </div>
            )}
          </nav>
        </div>

        <nav className={styles['secondary-nav']}>
          <div className={styles['secondary-nav-col']}>
            <h3>Köpa</h3>

            <ul>
              <li>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Text}
                  trailingIcon={arrowRight}
                  href="/kopa-begagnad-bil"
                >
                  Köpa bil
                </Button>
              </li>
              <li>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Text}
                  trailingIcon={arrowRight}
                  href="/boka-tid"
                >
                  Boka tid med Blipp
                </Button>
              </li>
            </ul>
          </div>

          <div className={styles['secondary-nav-col']}>
            <h3>Sälja</h3>

            <ul>
              <li>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Text}
                  trailingIcon={arrowRight}
                  href="/salja-begagnad-bil"
                >
                  Sälja begagnad bil
                </Button>
              </li>
              <li>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Text}
                  trailingIcon={arrowRight}
                  href="/salja-begagnad-bil"
                >
                  Värdera fordon
                </Button>
              </li>
            </ul>
          </div>

          <div className={styles['secondary-nav-col']}>
            <h3>Företag</h3>

            <ul>
              <li>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Text}
                  trailingIcon={arrowRight}
                  href="/foretag"
                >
                  Företag
                </Button>
              </li>
              <li>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Text}
                  trailingIcon={arrowRight}
                  href="/foretag/foretagsleasing"
                >
                  Företagsleasing
                </Button>
              </li>
              <li>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Text}
                  trailingIcon={arrowRight}
                  href="/foretag/release"
                >
                  Re:lease - Lägg om din leasing
                </Button>
              </li>
            </ul>
          </div>

          {isLoggedIn && (
            <div className={styles['secondary-nav-col']}>
              <h3>Mitt blipp</h3>

              <ul>
                <li>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/app/meddelanden"
                  >
                    Mina meddelanden
                  </Button>
                </li>
                <li>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/app/loan"
                  >
                    Mitt lån
                  </Button>
                </li>
                <li>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/app/dashboard"
                  >
                    Mina affärer
                  </Button>
                </li>
                <li>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    href="/app/settings"
                  >
                    Inställningar
                  </Button>
                </li>
                <li>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Text}
                    trailingIcon={arrowRight}
                    onClick={handleLogout}
                  >
                    Logga ut
                  </Button>
                </li>
              </ul>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};
