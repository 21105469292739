import React from 'react';
import { useRouter } from 'next/router';

import { Colors } from 'enums/Colors';
import { singleNeutral, menu } from 'icons/default';
import { Icon } from 'components/tokens/Icon';
// import { Modal } from 'components/Modal';
// import { Accordion, AccordionItem } from 'components/Accordion';
// import { Button, ButtonVariant, ButtonType } from 'components/Button';

import styles from './UserMenu.module.scss';

interface IProps {
  isInverted?: boolean;
  onClickMenu: () => void;
  isLoggedIn?: boolean;
}

export const UserMenu: React.FC<IProps> = ({ isLoggedIn, isInverted, onClickMenu }) => {
  const router = useRouter();

  return (
    <section className={styles['user-menu']}>
      <button className={styles.user} onClick={() => router.push('/app/dashboard')} type="button">
        <Icon icon={singleNeutral} color={isInverted ? Colors.White : undefined} />
        <span>{!isLoggedIn ? 'Logga in' : 'Mitt Blipp'} </span>
      </button>
      <button className={styles.hamburger} onClick={() => onClickMenu()} type="button">
        <Icon icon={menu} color={isInverted ? Colors.White : undefined} />
      </button>
    </section>
  );
};
